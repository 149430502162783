<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap mt-1">
      <h4 class="mb-1">
        {{ appTitle || $t("backoffice.faqs.title") }}
      </h4>
      <b-button
        v-if="isStaff"
        variant="primary"
        class="ml-1"
        @click="openModal = true"
      >
        {{ $t("backoffice.faqs.new-faq") }}
      </b-button>
    </div>
    <faq-accordion @edit="setFaqs($event)" />
    <!-- Modal -->
    <b-modal
      v-model="openModal"
      :title="
        isEdition
          ? $t('backoffice.faqs.edit-faq')
          : $t('backoffice.faqs.new-faq')
      "
      :ok-title="$t('form.actions.save')"
      ok-only
      :ok-disabled="!hasQuestion || !hasAnswer"
      @hidden="resetForm()"
      @ok="isEdition ? editFaq() : saveFaq()"
    >
      <b-form>
        <b-form-group>
          <label for="name" class="font-weight-bold">
            {{ $t("backoffice.faqs.question") }}*</label
          >
          <form-translation-table
            v-model="question"
            class="my-50"
            :value-placeholder="$t('layouts.widget-name')"
            label-attribute="name"
          />
        </b-form-group>
        <b-form-group>
          <label for="surnmame" class="font-weight-bold">
            {{ $t("backoffice.faqs.answer") }}*</label
          >
          <quill-editor
            v-model="answer[locale]"
            class="my-50"
            rows="5"
            :value-placeholder="$t('layouts.widget-name')"
            label-attribute="surnmame"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import FaqAccordion from "@/views/apps/faqs/components/FaqAccordion.vue";
import { BCard, BForm } from "bootstrap-vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import FormTranslationTable from "@core/components/form-translation-table/FormTranslationTable.vue";
import { quillEditor } from "vue-quill-editor";
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  components: {
    FaqAccordion,
    BCard,
    BForm,
    FormTranslationTable,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      page: 1,
      isEdition: false,
      question: {},
      answer: {},
      key: "",
      openModal: false,
    };
  },
  computed: {
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName
      );
    },
    app() {
      return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === 31)
    },
    hasQuestion() {
      return Object.values(this.question).filter(item=> {
        if (item != '') {
          return item
        }
      }).length > 0;
    },
    hasAnswer() {
      return Object.values(this.answer).filter(item=> {
        if (item != '') {
          return item
        }
      }).length > 0;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    fields() {
      return [
        {
          key: "question",
          label: this.$t("backoffice.faqs.question"),
        },
        {
          key: "answer",
          label: this.$t("backoffice.faqs.answer"),
        },
      ];
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    faq() {
      const index = this.$store.getters.faqs.unpaginated.findIndex(
        (item) => item.key === this.key
      );
      return this.$store.getters.faqs.unpaginated[index];
    },
  },

  methods: {
    resetForm() {
      this.question = {};
      this.answer = {};
      this.isEdition = false;
    },
    async saveFaq() {
      if (this.hasQuestion && this.hasAnswer) {
        const settings = {
          itemType: "faqs",
          customName: "faqs",
          requestConfig: {
            question: this.question,
            answer: this.answer,
          },
        };
        try {
          await this.$store.dispatch("createItem", {
            item: settings,
          });
          this.notifySuccess(this.$t("backoffice.faqs.messages.success"));
        } catch {
          this.notifyError(this.$t("backoffice.faqs.messages.error"));
        }
      } else {
        this.notifyError(this.$t("backoffice.faqs.messages.error"));
      }
    },
    setFaqs(event) {
      this.isEdition = true;
      this.key = event;
      this.openModal = true;
      this.question = { ...this.faq.question };
      this.answer = { ...this.faq.answer };
    },
    async editFaq() {
      this.faq.question = this.question;
      this.faq.answer = this.answer;
      const hasQuestion = Object.values(this.question).length > 0;
      const hasAnswer = Object.values(this.answer).length > 0;
      if (hasQuestion && hasAnswer) {
        const settings = {
          itemType: "faqs",
          customName: "faqs",
          requestConfig: {
            faqKey: this.key,
            question: this.question,
            answer: this.answer,
          },
        };
        try {
          await this.$store.dispatch("editItem", {
            item: settings,
          });
          this.notifySuccess(this.$t("backoffice.faqs.messages.success"));
        } catch {
          this.notifyError(this.$t("backoffice.faqs.messages.error"));
        }
      } else {
        this.notifyError(this.$t("backoffice.faqs.messages.error"));
      }
    },
  },
};
</script>
